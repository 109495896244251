body.animating.in.dimmable,
body.dimmed.dimmable {
  overflow: unset;
}

.ui.dimmer {
  background-color: rgba(0, 0, 0, .65);
}

.rw-date-picker.rw-widget input {
  border: none !important;
  vertical-align: baseline !important;
}

// Menu déroulant en haut à droite
.ui.menu .ui.dropdown .menu {
  margin-top: 0 !important;
}

// Pastilles sur les cadeaux dans la liste
.ui.floating.label.bottom {
  bottom: -1em;
  top: unset;
}
.ui.floating.label.left {
  left: 1em;
}
.ui.floating.icon {
  position: absolute;
}
.ui.floating.icon.bottom {
  bottom: -0.2em;
}
.ui.floating.icon.top {
  top: -0.8em;
}
.ui.floating.icon.left {
  left: -0.6em;
}
.ui.floating.icon.right {
  right: -0.8em;
}

.modalGiftContent {
  // Saisie montant dans popup cadeau
  .fields {
    display: flex;
    justify-content: space-between;
  }
  .inline.fields label {
    margin: auto 0 !important;
  }

  .inline.fields div.ui.input.numberInput  {
    display: inline-flex !important;
  }
  .ui.input.numberInput .ui.input > input {
    font-weight: bold;
    width: 4em;
    padding: 0;
    text-align: center !important;
  }

  .inline form {
    display: inline;
  }
  // Saisie montant dans popup cadeau --- END

  .container {
    position: relative;
  }
}

.pageSummary {
  .inputContribution input {
    width: 4em;
  }
  .inputContribution .inline {
    border-radius: 0;
  }
  .inputContribution button {
    border-top-left-radius: 0;
    border-bottom-left-radius: 0;
  }
}
